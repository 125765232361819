.startAlign {
    text-align: start;
}

.endAlign {
    text-align: end;
}

.leftAlign {
    text-align: left;
}

.rightAlign {
    text-align: right;
}

.centerAlign {
    text-align: center;
}

.justifyAlign {
    text-align: justify;
}

.match-parentAlign {
    text-align: match-parent;
}

.inheritAlign {
    text-align: inherit;
}

.initialAlign {
    text-align: initial;
}

.unsetAlign {
    text-align: unset;
}
